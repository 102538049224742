import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import $ from "jquery";

const Navcomponent = () => {
  const hidenav = () => {
    $("body").removeClass("active");
    document.body.style.overflow = "visible";
    $(".navbar-collapse").removeClass("show");
    $(".toggletwoicon,.iconone,.icontwo,.togglefouricon").removeClass("active");
    $(".navbar-toggler").removeClass("collapsed");
  };
  return (
    <>
     <Navbar expand="lg" className="">
        <Container>
          <Navbar.Brand>
            <Link className="nav-link" smooth={true} to="/">
              <img
                src={"./assets/images/Brand Logo.png"}
                className="logomain"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="navbar-toggler active"
          >
            <div class="toggletwoicon">
              <span class="navbar-toggler-icon iconone"></span>
              <span class="navbar-toggler-icon icontwo"></span>
            </div>
            <div class="togglefouricon">
              <span class="navbar-toggler-icon iconthree"></span>
              <span class="navbar-toggler-icon iconfour"></span>
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Brand to="/" className="moblogbrand">
              <Link className="nav-link" smooth={true} to="/">
                <img
                  src={"./assets/images/BrandLogomob.png"}
                  className="moblogo"
                  alt="logo"
                />
              </Link>
            </Navbar.Brand>
            <div className="middlenav">
              <Nav className="">
                <ul className="navul">
                  <li>
                    <NavLink
                      onClick={hidenav}
                      className="nav-link nav-underline { window.location.pathname === nav.link ? “active” : “” }"
                      smooth={true}
                      to="/about-us"
                    >
                      ABOUT US
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={hidenav}
                      className="nav-link nav-underline"
                      smooth={true}
                      to="/products"
                    >
                      PRODUCTS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={hidenav}
                      className="nav-link nav-underline"
                      smooth={true}
                      to="/projects"
                    >
                      PROJECTS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={hidenav}
                      className="nav-link nav-underline"
                      smooth={true}
                      to="/services"
                    >
                      SERVICES
                    </NavLink>
                  </li>
                </ul>
              </Nav>
            </div>
            <div className="endnav">
              <Link
                onClick={hidenav}
                className="nav-link white contactbtn btn"
                smooth={true}
                to="/contact-us"
              >
                CONTACT US
              </Link>
            </div>
            <div className="mobilenav-img">
              <img
                src={"./assets/images/"}
                className="moblogo2"
                alt="logo"
              />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ToastContainer />
    </>
  );
};

export default Navcomponent;
