import React, { useEffect, useState, useRef } from "react";
import Navcomponent from "./navbar";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Phone from "./phone";
import Contact from "./contact";
import SplitType from "split-type";
import { motion as m } from "framer-motion";
import { gsap, Power2 } from "gsap";

const Contactus = () => {
  const form = useRef();
  const [formState, setFormState] = useState({});
  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });
    });

    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4ikopok",
        "template_ijgmu1o",
        form.current,
        "IyA-bzBzbkBDltsgB"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you! We've received your message. Tean will contact you shortly.",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        },
        (error) => {
          toast.error("Incorrect Email", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <section id="contact-us">
          <div className="container borderbottom pb100 h-80 contact-container">
            <div className="row landingrow">
              <div className="col-md-10">
                <div className="">
                  <div className="contactus-heading">
                    <h1 className="grotesklight animateheading">
                      CONTACT US NOW FOR
                    </h1>
                    <h1 className="grotesklight animateheading">
                    TAILORED ALUMINIUM SOLUTIONS
                    </h1>
                 
                  </div>
                  <div className="contactus-headingmobile">
                    <h1 className="grotesklight animateheading">CONTACT US</h1>
                    <h1 className="grotesklight animateheading">
                      NOW FOR TAILORED
                    </h1>
                    <h1 className="grotesklight animateheading">ALUMINIUM</h1>
                    <h1 className="grotesklight animateheading">SOLUTIONS</h1>
                  </div>
                </div>
              </div>
              <div className="col-md-5 item-end"></div>
            </div>
          </div>
          <div className="container pb100 ptop100 borderbottom">
            <div className="row">
              <div className="col-md-5">
                <div className="contact-content">
                  <h1 className="grotesklight">LET’S START WORKING!</h1>
                  <p className="grotesklight">
                    Connect with Aluminum Masters to embark on a journey where
                    innovation meets your ideas. Our expert team is always ready
                    to transform concepts as well as your requirements into
                    tangible elegance.
                  </p>
                </div>
              </div>
              <div className="col-md-6 offset-md-1">
                <form ref={form} onSubmit={sendEmail} className="emailform">
                  {/* <form ref={form} className="emailform"> */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control contactform"
                          id="exampleInputFname"
                          placeholder="First Name *"
                          name="fname"
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control contactform"
                          id="exampleInputFname"
                          placeholder="Last Name *"
                          name="lname"
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control contactform"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email Address *"
                          name="email"
                          onChange={changeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="phone"
                          className="form-control contactform"
                          id="exampleInputPhone"
                          aria-describedby=""
                          placeholder="Phone Number *"
                          name="phone"
                          onChange={changeHandler}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group">
                        <textarea
                          className="form-control contactform"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          placeholder="Your Message"
                          name="message"
                          onChange={changeHandler}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="submitemail">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="patternaboutus patternservice">
            <img
              src={"./assets/images/mainPattern.png"}
              className=""
              alt="pattern"
            />
          </div>
        </section>
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

export default Contactus;
