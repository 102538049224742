import React, { useEffect } from "react";
import Landing from "./landingMain";
import Navcomponent from "./navbar";
import Contact from "./contact";
import Portfolio from "./portfolio";
import About from "./about";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import Team from "./team";
import Phone from "./phone";
import Clients from "./clients";
import SplitType from "split-type";
import { gsap, Power2 } from "gsap";
import { motion as m } from "framer-motion";
gsap.registerPlugin(ScrollSmoother);

const Home = () => {
  // useEffect(() => {
  //   ScrollSmoother.create({
  //     wrapper: "#smooth-wrapper",
  //     content: "#smooth-content",
  //     smooth: 2,
  //     smoothTouch: 0.1,
  //     effects: true,
  //   });
  // }, []);
  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });
    });

    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);

  return (
    <>
      {/* <a id="back-to-top" href="#" className="back-to-top" role="button"><IoIosArrowUp className="upicon"/></a> */}
      {/* <div className="smooth-wrapper" id="smooth-wrapper">
                <div id="smooth-content"> */}
      <m.div
        // initial={{ scaleY: 0 }}
        // animate={{ scaleY: 1 }}
        // exit={{ scaleY: 0 }}
        // transition={{ duration: 1.5 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <Landing />
        <Portfolio />
        <About />
        <Team />
        <Clients />
        <Phone />
        <Contact />
      </m.div>
      {/* </div>
            </div> */}
    </>
  );
};

export default Home;
