import React,{useEffect} from "react";
import Button from "react-bootstrap/Button";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap,Power2 } from "gsap";
import { HashLink as Link } from "react-router-hash-link";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  return (
    <>
      <section id="aboutsection">
        <div className="container">
          <div className="row landingrow">
            <div className="col-md-6">
              <div className="">
                <div className="about-heading">
                  <h1 className="grotesklight">ABOUT US</h1>
                </div>
                <div className="aboutcontent-parent">
                  <p>
                    Aluminum Masters is more than just a company; it’s a vision
                    for a future where design and functionality coexist in
                    perfect harmony. Our journey began with a simple idea: to
                    transform ordinary spaces into extraordinary ones.
                    <br />
                    <br />
                    With a commitment to innovation and sustainability, we
                    strive to push the boundaries of what’s possible. Using
                    aluminum as a tool of beautification, we transform ordinary
                    places into aesthetically pleasing modern abodes.
                  </p>
                </div>
                <Link to="/about-us">
                  <Button type="" className="discovermorebtn">
                    Learn More{" "}
                    <img
                      src={"./assets/images/arrow.svg"}
                      className="arrow"
                      alt="logo"
                    />
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 item-end">
             <div className="reveal">
                  <img
                    src={"./assets/images/homeabout.png"}
                    className="about-img"
                    alt="aboutus"
                  />
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
