import React, { useEffect } from "react";
import Clients from "./clients";
import Phone from "./phone";
import Contact from "./contact";
import Navcomponent from "./navbar";
import { gsap, Power2 } from "gsap";
import SplitType from "split-type";
import { motion as m } from "framer-motion";
import OwlCarousel from "react-owl-carousel";
// import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const AboutUs = () => {
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });

      // let tl1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: revealtext,
      //     toggleActions: "restart none none reset",
      //   },
      // });

      // tl1.to(headingreveal, {
      //   y: 0,
      //   stagger: 0.05,
      //   delay: 0.3,
      //   duration: 0.1,
      // });
    });

    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <section id="about-us">
          <div className="container">
            <div className="row aboutlandingrow">
              <h1 className="grotesklight about-usheading animateheading">
                REDEFINING EXCELLENCE IN
              </h1>
              <h1 className="grotesklight about-usheading animateheading">
                ALUMINIUM CRAFTSMANSHIP
              </h1>
            </div>
            <div className="row landingrowmobie">
              <h1 className="grotesklight about-usheading animateheading">
                REDEFINING
              </h1>
              <h1 className="grotesklight about-usheading animateheading">
                EXCELLENCE IN
              </h1>
              <h1 className="grotesklight about-usheading animateheading">
                ALUMINIUM
              </h1>
              <h1 className="grotesklight about-usheading animateheading">
                CRAFTSMANSHIP
              </h1>
            </div>
          </div>
          <div className="patternaboutus">
            <img
              src={"./assets/images/BrandPattern2.svg"}
              className=""
              alt="pattern"
            />
          </div>
        </section>
        <section id="aboutUS">
          <div className="container">
            <div className="row pb100 borderbottom">
              <div className="col-md-6">
                <div className="landing-content">
                  <div className="aboutus-heading">
                    <h1 className="grotesklight animateheading1">
                      EXCEEDING EXPECTATIONS
                    </h1>
                    <h1 className="grotesklight animateheading2">
                      IN EVERY PROJECT
                    </h1>
                  </div>
                  <div className="aboutuscontent-parent">
                    <p>
                      Aluminium Masters is more than just a company; it’s a
                      vision for a future where design and functionality coexist
                      in perfect harmony. Our journey began with a simple idea:
                      to transform ordinary spaces into extraordinary ones. We
                      believe that every home deserves the best, and we strive
                      to provide high-quality, modern aluminum products that
                      elevate your living experience.
                      <br />
                      <br />
                      Our commitment to innovation is at the heart of everything
                      we do. We are constantly pushing the boundaries of what’s
                      possible, exploring new designs and techniques to bring
                      you products that are not only beautiful but also
                      practical.
                      <br />
                      <br />
                      Our team of dedicated professionals works tirelessly to
                      ensure that every product we create meets our high
                      standards of quality and design.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 offset-md-1 item-end">
                <div className="reveal">
                  <img
                    src={"./assets/images/aboutus.png"}
                    className="about-img"
                    alt="aboutus"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="mission">
          <div className="container">
            <div className="row landingrow pb100 missionrow">
              <div className="col-md-6">
                <div className="landing-content">
                  <div className="mission-heading">
                    <h1 className="grotesklight orange">OUR MISSION</h1>
                  </div>
                  <div className="ptop20">
                    <p className="black">
                      We utilize our knowledge and sustainable methods in
                      cutting-edge technology to set the benchmark in industry
                      standards. We consistently exceed the expectations of
                      consumers with solution-focused aluminium products. Our
                      innovative design combined with ethical practices make us
                      the preffered choice in the market.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 offset-md-1 item-end">
                <div className="reveal">
                  <img
                    src={"./assets/images/Mission.png"}
                    className="about-img"
                    alt="projects"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row landingrow pb100 vissionrow">
              <div className="col-md-5 item-end">
                <div className="reveal">
                  <img
                    src={"./assets/images/Vission.png"}
                    className="about-img"
                    alt="projects"
                  />
                </div>
              </div>
              <div className="col-md-6 offset-md-1">
                <div className="landing-content">
                  <div className="vission-heading">
                    <h1 className="grotesklight orange vission-heading">
                      OUR VISION
                    </h1>
                  </div>
                  <div className="ptop20">
                    <p className="black">
                      AT Aluminium Masters we envision a world where innovation and sustainability converge seamlessly. As a leading aluminium solution provider our vission is to redefine the future of industries by delivering cutting-edge eco-friendly alminium products. We aspire to be the catalyst for positive change, forging a path towards a greener and more resilient tomorrow.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container pb100">
            <div className="row">
              <div className="col-md-6">
                <div className="portfolio-heading">
                  <h1 className="grotesklight team-heading">
                    MEET THE TEAM
                  </h1>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="product-figure-parent product-figure-parent1">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/faran.png"}
                      alt="projects"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">FARAN TARIQ</h2>
                      <p className="grotesklight">FOUNDER & CEO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="product-figure-parent product-figure-parent2">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/samuel.png"}
                      alt="projects"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">SAMUEL CHANG</h2>
                      <p className="grotesklight">CO-FOUNDER & CTO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="product-figure-parent product-figure-parent3">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/enzo.png"}
                      alt="projects"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">ENZO MARATA</h2>
                      <p className="grotesklight">DIRECTOR OPERATIONS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="imagecarousel">
          <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={20}
            nav={false}
            dots={false}
            items={3}
            autoplay={true}
            //   autoPlayTimeout={1000}
            autoPlayHoverPause={true}
            slideTransition="linear"
            autoPlaySpeed={6000}
            smartSpeed={6000}
            responsive={state.responsive}
          >
            <div class="item">
              <img src={"./assets/images/slider1.png"} className="item1img" alt="projects" />
            </div>
            <div class="item">
              <img src={"./assets/images/slider2.png"} className="item1img" alt="projects" />
            </div>
            <div class="item">
              <img src={"./assets/images/slider3.png"} className="item1img" alt="projects" />
            </div>
          </OwlCarousel>
        </section>
        <section id="imagecarousel2" className="ptop20">
          <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={20}
            nav={false}
            dots={false}
            items={3}
            autoplay={true}
            rtl={true}
            //   autoPlayTimeout={1000}
            autoPlayHoverPause={true}
            slideTransition="linear"
            autoPlaySpeed={12000}
            smartSpeed={12000}
            //   responsive={state.responsive}
          >
            <div class="item">
              <img src={"./assets/images/slide4.png"} className="item1img" alt="projects" />
            </div>
            <div class="item">
              <img src={"./assets/images/slide5.png"} className="item1img" alt="projects"/>
            </div>
            <div class="item">
              <img src={"./assets/images/slide6.png"} className="item1img" alt="projects" />
            </div>
          </OwlCarousel>
        </section>
        <Clients />
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

export default AboutUs;
