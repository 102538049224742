import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Team = () => {
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <section id="teamsection">
        <div className="container">
          <h1 className="grotesklight">WHAT THEY SAY?</h1>
          <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={10}
            nav={false}
            dots={false}
            items={2}
            autoplay={false}
            autoPlayTimeout={1000}
            autoPlayHoverPause={true}
            // slideTransition='linear'
            // autoPlaySpeed={6000}
            // smartSpeed={6000}
            responsive={state.responsive}
          >
            <div class="item">
              <div className="row mt-100 mb-100">
                <div className="col-md-6">
                  <img
                    src={"./assets/images/mathias.png"}
                    className="item1img"
                    alt="team"
                  />
                </div>
                <div className="col-md-6 team-content">
                  <h2 className="grotesklight white">
                    “Choosing Aluminum Masters was a great decision as it
                    completely transformed my perception of home décor. Through
                    aluminum, they’ve just made my house look elegant and
                    beautiful.”
                  </h2>
                  <div className="team-title">
                    <h3 className="grotesklight white">Mattias Svensson</h3>
                    <p className="grotesklight white">
                      bedroom interior - villa owner in lesbos - Greece
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div className="row mt-100 mb-100">
                <div className="col-md-6">
                  <img src={"./assets/images/julie.png"} className="item1img" alt="team"/>
                </div>
                <div className="col-md-6 team-content">
                  <h2 className="grotesklight white">
                    “I am so impressed by the craftsmanship of Aluminum Masters.
                    They have delivered beyond my expectations.”
                  </h2>
                  <div className="team-title">
                    <h3 className="grotesklight white">Julie Lattes</h3>
                    <p className="grotesklight white">
                      kitchen interior - flat owner in Bologna - Italy
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div className="row mt-100 mb-100">
                <div className="col-md-6">
                  <img
                    src={"./assets/images/holger.png"}
                    className="item1img"
                    alt="team"
                  />
                </div>
                <div className="col-md-6 team-content">
                  <h2 className="grotesklight white">
                    “Aluminium Masters has exceeded my expectations with their
                    innovative designs and excellent customer service. My home
                    has never looked better. Thank you, Aluminium Masters!”
                  </h2>
                  <div className="team-title">
                    <h3 className="grotesklight white">Holger Lindhardt</h3>
                    <p className="grotesklight white">
                      wardrobe interior - home owner in Paris - France
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
        <div className="pattern">
          <img
            src={"./assets/images/Brand Pattern.svg"}
            className="pattern-img"
            alt="team"
          />
        </div>
      </section>
    </>
  );
};

export default Team;
