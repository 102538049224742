import React, { useEffect } from "react";
import { gsap, Power2 } from "gsap";
import Navcomponent from "./navbar";
import Button from "react-bootstrap/Button";
import Phone from "./phone";
import Contact from "./contact";
import SplitType from "split-type";
import { motion as m } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";

const Products = () => {
  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });
    });

    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <section id="products">
          <div className="container">
            <div className="row landingrow pb100">
              <div className="col-md-10">
                <div className="landing-content">
                  <div className="product-heading">
                    <h1 className="grotesklight animateheading">
                      ENHANCE YOUR SPACE WITH
                    </h1>
                    <h1 className="grotesklight animateheading">
                      PREMIUM ALUMINIUM SOLUTIONS
                    </h1>
                    <p>
                      Elevate your living space with our top-grade aluminum
                      interior<br/> solutions. Crafted for modern luxury, lasting
                      quality, and timeless style.
                    </p>
                  </div>
                  <div className="product-heading product-mobile">
                    <h1 className="grotesklight animateheading">
                      ENHANCE YOUR
                    </h1>
                    <h1 className="grotesklight animateheading">SPACE WITH</h1>
                    <h1 className="grotesklight animateheading">
                      PREMIUM ALUMINIUM
                    </h1>
                    <h1 className="grotesklight animateheading">SOLUTIONS</h1>
                  </div>
                  {/* <div className="aboutuscontent-parent product-contentparent">
                    <p data-aos="fade-left" data-aos-duration="1000">
                      Elevated living spaces with a fusion of premium aluminium
                      solutions. Luxurious, Effortless, and timeless.
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-md-2 item-end">
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="reveal">
                      <img
                        src={"./assets/images/p1.png"}
                        className="about-img"
                        alt="product"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="reveal">
                      <img
                        src={"./assets/images/p2.png"}
                        className="about-img"
                        alt="product"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="reveal">
                      <img
                        src={"./assets/images/p3.png"}
                        className="about-img"
                        alt="product"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="patternaboutus">
            <img
              src={"./assets/images/BrandPattern2.svg"}
              className=""
              alt="pattern"
            />
          </div>
        </section>
        <section id="allProducts">
          <div className="container pb100 borderbottom">
            <div className="row">
              <div className="col-md-6">
                <div className="">
                  <h1 className="grotesklight ourproduct-heading">OUR PRODUCTS</h1>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="product-figure-parent">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/doorimg1.png"}
                      alt="product"
                    />
                    <img
                      className="Sirv image-hover"
                      src={"./assets/images/doorimg2.png"}
                      alt="product"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">OUT DOOR CANOPY</h2>
                      <p className="grotesklight">
                        Outdoor, Door Décor, Windows Interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="product-figure-parent">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/locker.png"}
                      alt="product"
                    />
                    <img
                      className="Sirv image-hover"
                      src={"./assets/images/lockker2.png"}
                      alt="product"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">LOCKER</h2>
                      <p className="grotesklight">
                        Indoor, Security, Home interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="product-figure-parent">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/shoerac.png"}
                      alt="product"
                    />
                    <img
                      className="Sirv image-hover"
                      src={"./assets/images/Product IMG 2-3.png"}
                      alt="product"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">SHOE RACK</h2>
                      <p className="grotesklight">
                        Indoor, Cleanliness , Home interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="product-figure-parent">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/shoerack3.png"}
                      alt="product"
                    />
                    <img
                      className="Sirv image-hover"
                      src={"./assets/images/Product IMG 3-1.png"}
                      alt="product"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">SHOE RACK #2</h2>
                      <p className="grotesklight">
                        Indoor, Cleanliness , Home interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="product-figure-parent">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/shoerac.png"}
                      alt="product"
                    />
                    <img
                      className="Sirv image-hover"
                      src={"./assets/images/shoerack3hover.png"}
                      alt="product"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">SHOE RACK #3</h2>
                      <p className="grotesklight">
                        Indoor, Cleanliness , Home interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="product-figure-parent">
                  <div class="product-figure">
                    <img
                      className="Sirv image-main"
                      src={"./assets/images/wardrobe6.png"}
                      alt="product"
                    />
                    <img
                      className="Sirv image-hover"
                      src={"./assets/images/Product IMG 2-2.png"}
                      alt="product"
                    />
                  </div>
                  <div className="product-content">
                    <div className="left-content">
                      <h2 className="grotesklight">WARDROBE</h2>
                      <p className="grotesklight">
                        Indoor, Bedroom Interior , Home interior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/products">
              <Button
                type=""
                className="discovermorebtn"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Load More{" "}
              </Button>
            </Link>
          </div>
        </section>
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

let array = [11, 6, 10, 20, 18];

for (let i = 0; i < array.length; i++) {
  if (array[i] > array[i + 1]) {
    let temp = array[i];
    array[i] = array[i + 1];
    array[i + 1] = temp;
    // i= -1
  }
}
console.log(array);
export default Products;
