import React from "react";
import Button from "react-bootstrap/Button";
import { HashLink as Link } from "react-router-hash-link";

const Landing = () => {
  return (
    <>
      <section id="Landingsection">
        <div className="container">
          <div className="row landingrow">
            <div className="col-md-7">
              <div className="landing-content topcontent">
                <div className="landing-heading">
                  <h1 className="grotesklight animateheading">
                    TRANSFORM YOUR
                  </h1>
                  <h1 className="grotesklight animateheading">
                    LIVING STANDARD
                  </h1>
                </div>
                <div className="landingcontent-parent">
                  <p>
                    Discover the fusion of functionality and style with our
                    modern<br/> aluminum products. Elevate your kitchen and home
                    interiors to<br/> new heights.
                  </p>
                </div>
                <div className="landingcontent-parentmobile">
                  <p>
                    Discover the fusion of functionality and style with our
                    modern aluminum products. Elevate your kitchen and home
                    interiors to new heights.
                  </p>
                </div>
                {/* <Link to="/contact-us">
                  <Button
                    type=""
                    className="discovermorebtn"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    Discover More{" "}
                    <img
                      src={"./assets/images/arrow.svg"}
                      className="arrow"
                      alt="logo"
                    />
                  </Button>
                </Link> */}
              </div>
            </div>
            <div className="col-md-5 item-end">
              {/* <div className="reveal">
                <img
                  src={"./assets/images/landing.png"}
                  className="historyimg1 about-img"
                  alt="product"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Landing;
