import React from "react";
import Button from "react-bootstrap/Button";
import { HashLink as Link } from "react-router-hash-link";

const Portfolio = () => {
  return (
    <>
      <section id="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="portfolio-heading">
                <h1 className="grotesklight animateheading">PORTFOLIO</h1>
                <p
                  className="pbottom50 black"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  Our portfolio is a testament to modern design and innovation.
                  Take a look at our completed projects.
                </p>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={"./assets/images/Product IMG1.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">
                      kitchen interior
                    </span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">
                    La setnanz itallian
                  </span>
                </div>
              </div>
              <div className="col-md-12 ptop50">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={"./assets/images/Product IMG3.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">Office Interior</span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">
                    Latern jerzos nesa
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={"./assets/images/Product IMG2.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">
                      Bedroom Interior
                    </span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">
                    inter linsenz liber
                  </span>
                </div>
              </div>
              <div className="col-md-12 ptop50">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={"./assets/images/Product IMG4.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">
                      Wardrobe Interior
                    </span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">
                    Franza seorta ichi
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Link to="/projects">
            <Button type="" className="discovermorebtn">
              Browse Projects{" "}
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
