import React, { useEffect } from "react";
import Navcomponent from "./navbar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Phone from "./phone";
import Contact from "./contact";
import { gsap, Power2 } from "gsap";
import SplitType from "split-type";
import { motion as m } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";

const Projects = () => {
  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });
    });

    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <section id="projects">
          <div className="container pb100 h-80">
            <div className="row landingrow">
              <div className="col-md-10">
                <div className="">
                  <div className="projects-heading">
                    <h1 className="grotesklight animateheading">
                      ALUMINIUM CRAFTSMANSHIP
                    </h1>
                    <h1 className="grotesklight animateheading">
                      AT ITS FINEST!
                    </h1>
                    <div className="service-maincontent">
                      <p>
                        From bespoke designs to seamless installations, each
                        project narrates a story of
                        <br /> aluminum craftsmanship at its finest.
                      </p>
                    </div>
                  </div>
                  <div className="projects-headingmobile">
                    <h1 className="grotesklight animateheading">ALUMINIUM</h1>
                    <h1 className="grotesklight animateheading">
                      CRAFTSMANSHIP
                    </h1>
                    <h1 className="grotesklight animateheading">
                      AT ITS FINEST!
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-md-5 item-end"></div>
            </div>
          </div>
          <div className="patternaboutus patternservice">
            <img
              src={"./assets/images/mainPattern.png"}
              className=""
              alt="pattern"
            />
          </div>
        </section>
        <section id="projects-tab">
          <div className="container">
            <div className="row borderbottom">
              <div className="col-md-6">
                <div className="portfolio-heading">
                  <h1 className="grotesklight animateheading">
                    PROJECTS
                  </h1>
                  <p
                    className="pbottom50 black"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    Our projects showcase the artistry and expertise that define
                    our commitment to excellence.
                  </p>
                </div>
              </div>
              <div className="col-md-6"></div>
              <Tabs defaultActiveKey="first">
                <Tab eventKey="first" title="ALL">
                  <div className="">
                    <div className="row ptop50">
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG1.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #1</h2>
                          <h2 className="grotesklight date">08 / 09 / 2022</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG2.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #2</h2>
                          <h2 className="grotesklight date">15 / 11 / 2022</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row ptop20">
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG3.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #3</h2>
                          <h2 className="grotesklight date">01 / 05 / 2023</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG4.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #4</h2>
                          <h2 className="grotesklight date">03 / 20 / 2023</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/projects" className="nounderline">
                      <Button type="" className="discovermorebtn">
                        Browse Projects{" "}
                      </Button>
                    </Link>
                  </div>
                </Tab>
                <Tab eventKey="second" title="KITCHEN INTERIORS">
                  <div className="">
                    <div className="row ptop50">
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG1.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #1</h2>
                          <h2 className="grotesklight date">08 / 09 / 2022</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG2.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #2</h2>
                          <h2 className="grotesklight date">15 / 11 / 2022</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row ptop20">
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG3.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #3</h2>
                          <h2 className="grotesklight date">01 / 05 / 2023</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG4.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #4</h2>
                          <h2 className="grotesklight date">03 / 20 / 2023</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                    </div>
                    <Button type="" className="discovermorebtn">
                      Browse Projects{" "}
                    </Button>
                  </div>
                </Tab>
                <Tab eventKey="third" title="OFFICE INTERIORS">
                  <div className="">
                    <div className="row ptop50">
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG1.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #1</h2>
                          <h2 className="grotesklight date">08 / 09 / 2022</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG2.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #2</h2>
                          <h2 className="grotesklight date">15 / 11 / 2022</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row ptop20">
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG3.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #3</h2>
                          <h2 className="grotesklight date">01 / 05 / 2023</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="hover01 columnproject">
                          <div>
                            <figure>
                              <img
                                src={"./assets/images/allIMG4.png"}
                                alt="revolve"
                                className="projectimgs"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="project-content">
                          <h2 className="grotesklight">Project #4</h2>
                          <h2 className="grotesklight date">03 / 20 / 2023</h2>
                          <p className="grotesklight grey">
                            keyword #1, keyword #2, keyword #3
                          </p>
                        </div>
                      </div>
                    </div>
                    <Button type="" className="discovermorebtn">
                      Browse Projects{" "}
                    </Button>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </section>
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

export default Projects;
